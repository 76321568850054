<template>
  <v-row justify="center" id="home-about-dates">
    <v-col cols="12" md="8">
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold">About</v-card-title>
        <!-- ���� �ϴ� ���� �߰� -->
        <v-card-text class="text-body">
          The 26th conference of the International Society for Music Information
          Retrieval (ISMIR) will be held in Daejeon, Korea from September 21-25,
          2025. ISMIR is the largest research venue for scholars, researchers,
          practitioners, and industries in the field of Music Information
          Retrieval (MIR)-an interdisciplinary area focused on processing,
          analyzing, indexing, organizing, and accessing music-related data.
          More program details will be available soon, so please stay tuned!
        </v-card-text>
        <br />
        <v-divider></v-divider>
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Important Dates</v-card-title
        >
        <v-card-text class="text-body">
          All deadlines are at 23:59 AoE.
        </v-card-text>

        <v-card-title class="text-h5" style="color: grey"
          >Call for Papers</v-card-title
        >

        <v-container class="table-container">
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Abstract Submission Due</v-col>
            <v-col cols="5" sm="8">Mar 21, 2025</v-col>
          </v-row>
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Full Paper Submission Due</v-col>
            <v-col cols="5" sm="8">Mar 28, 2025</v-col>
          </v-row>
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Acceptance Notification</v-col>
            <v-col cols="5" sm="8">Jun 6, 2025</v-col>
          </v-row>
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Camera-Ready Upload Due</v-col>
            <v-col cols="5" sm="8">Jun 28, 2025</v-col>
          </v-row>
        </v-container>

        <br />

        <!--
        <v-card-title class="text-h5" style="color: grey"
          >Music submissions</v-card-title
        >

        <v-container class="table-container">
          <v-row class="table-row">
            <v-col cols="6">Submission opening</v-col>
            <v-col cols="6">2025.00.00.</v-col>
          </v-row>
          <v-row class="table-row">
            <v-col cols="6">Submission</v-col>
            <v-col cols="6">2025.00.00.</v-col>
          </v-row>
          <v-row class="table-row">
            <v-col cols="6">Notification of acceptance</v-col>
            <v-col cols="6">2025.00.00.</v-col>
          </v-row>
          <v-row class="table-row">
            <v-col cols="6">Final version submission</v-col>
            <v-col cols="6">2025.00.00.</v-col>
          </v-row>
        </v-container>
        <br />

        <v-card-title class="text-h5 my-3" style="color: grey"
          >Registration</v-card-title
        >

        <v-container class="table-container">
          <v-row class="table-row">
            <v-col cols="6">Author registration</v-col>
            <v-col cols="6">2025.00.00.</v-col>
          </v-row>
          <v-row class="table-row">
            <v-col cols="6">Early Bird Registration</v-col>
            <v-col cols="6">2025.00.00.</v-col>
          </v-row>
        </v-container>
        <br />
        <v-divider></v-divider>
        -->
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Submission Portal</v-card-title
        >
        <v-card-text class="text-body">
          <a
            href="https://cmt3.research.microsoft.com/ISMIR2025/"
            target="_blank"
            style="color: #1487c8"
            >https://cmt3.research.microsoft.com/ISMIR2025/
          </a>
        </v-card-text>
      </v-card>

      <v-divider></v-divider>
      <br />
      <!-- Sposer ���� �߰� -->
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Special Thanks</v-card-title
        >
        <v-card-text class="text-body">
          Conference Sponsors and Exhibitors
        </v-card-text>

        <!-- �ΰ� �̹������� ��ġ�ϴ� v-row�� v-col -->
        <v-row justify="center" class="mt-4">
          <v-col cols="4" md="2" class="d-flex justify-center">
            <img :src="kaistLogo" alt="Logo 1" class="logo-image" />
          </v-col>
          <v-col cols="4" md="2" class="d-flex justify-center">
            <img :src="sogangLogo" alt="Logo 2" class="logo-image" />
          </v-col>
          <v-col cols="4" md="2" class="d-flex justify-center">
            <img :src="dtoLogo" alt="Logo 3" class="logo-image" />
          </v-col>
        </v-row>
        <br />
        <v-divider></v-divider>
      </v-card>
      <br />
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >General inquiries</v-card-title
        >

        <v-card-text class="text-body">
          <span>ismir2025@ismir.net</span>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="copyEmail">Copy Email</v-btn>
        </v-card-actions>
      </v-card>
      <br /><br /><br />
    </v-col>
  </v-row>
</template>

<script setup>
import { useClipboard } from "@vueuse/core";
import kaistLogo from "@/assets/sponserLogos/kaist.png";
import sogangLogo from "@/assets/sponserLogos/sogang.png";
import dtoLogo from "@/assets/sponserLogos/daejeonTourismOrganization.png";
const { copy } = useClipboard();
const email = "ismir2025@ismir.net";

function copyEmail() {
  copy(email).then(() => {
    alert("Email address copied to clipboard!");
  });
}
</script>

<style scoped>
.logo-image {
  width: 100%;
  height: 100px; /* �ΰ� �̹����� ������ ���� */
  object-fit: contain; /* ���� �̹��� ������ �����ϸ鼭 �����̳ʿ� �°� ���� */
}

.text-body {
  font-size: 18px; /* �ؽ�Ʈ ��Ʈ ũ�� ���� */
  line-height: 1.6; /* �������� ���� �� ���� ���� */
}

.table-row v-col {
  font-size: 16px; /* ǥ�� �ؽ�Ʈ ��Ʈ ũ�� ���� */
}
.styled-row {
  border-bottom: 1px solid #d3d3d3; /* ȸ�� �� */
  padding-bottom: 8px; /* �ٰ� ���� �� ���� */
}
</style>
