<template>
  <v-row justify="center" id="call-for-papers">
    <v-col cols="12" md="8">
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Overview</v-card-title
        >
        <v-card-text class="text-body">
          ISMIR 2025 welcomes contributions in all the areas related to Music
          Information Retrieval (MIR) and its applications, including
          computational music analysis, processing, generation, algorithms, and
          their evaluation. ISMIR is a truly interdisciplinary community, which
          fosters collaboration between researchers, developers, educators,
          librarians, students, and professionals from the disciplines involved
          in MIR, such as musicology, cognitive science, library and information
          science, computer science, electrical engineering, and many others.
          ISMIR 2025 will foster the discussion and exchange of ideas among the
          attendees, with special attention to new topics, emerging problems,
          inclusion, and diversity.
          <br /><br />
          The 26th edition of the ISMIR conference carries a special theme:
          <span
            style="
              color: #004191;
              font-style: italic;
              font-weight: bold;
              text-decoration: underline;
            "
            >Harmony of Tradition & Modernity.</span
          >
          We encourage diverse perspectives on how MIR can bridge past and
          present, and explore the multifaceted intersections of tradition and
          innovation. From the preservation and analysis of traditional music
          forms to the study of contemporary music trends powered by
          computational methods and data, we welcome submissions that advance
          the understanding of music as a dynamic and evolving cultural force.
          Join us in shaping the future of MIR by engaging with its rich
          historical roots and its ever-expanding horizons.
          <br />
        </v-card-text>
        <br />
        <v-divider></v-divider>
        <br />
      </v-card>
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Topics</v-card-title
        >
        <v-card-title class="text-h6 mb-3"
          >Relevant topics for ISMIR 2025 include, but are not limited
          to:</v-card-title
        >
        <ul class="dot-list">
          <li>
            <span class="highlight-text">
              MIR fundamentals and methodology
            </span>
            : music signal processing; symbolic music processing; metadata,
            tags, linked data, and semantic web; lyrics and other textual data;
            web mining, and natural language processing; multimodality.
          </li>
          <li>
            <span class="highlight-text"
              >Knowledge-driven approaches to MIR</span
            >: representations of music; computational music theory and
            musicology; cognitive MIR; machine learning/artificial intelligence
            for music; computational ethnomusicology.
          </li>
          <li>
            <span class="highlight-text">Musical features and properties</span>:
            melody and motives; harmony, chords and tonality; rhythm, beat,
            tempo; structure, segmentation, and form; representations of music;
            timbre, instrumentation, and singing voice; musical style and genre;
            musical affect, emotion and mood; expression and performative
            aspects of music.
          </li>
          <li>
            <span class="highlight-text">MIR tasks</span>: sound source
            separation; music transcription and annotation; optical music
            recognition; alignment, synchronization, and score following; music
            summarization; fingerprinting; automatic classification; indexing
            and querying; pattern matching and detection; similarity metrics.
          </li>
          <li>
            <span class="highlight-text">Generative tasks</span>: music and
            audio synthesis; transformations; interactions; real-time
            considerations; evaluation metrics; qualitative evaluations;
            artistically-inspired generative tasks.
          </li>
          <li>
            <span class="highlight-text"
              >Evaluation, datasets, and reproducibility</span
            >: evaluation methodology; evaluation metrics; novel datasets and
            use cases; annotation protocols; reproducibility.
          </li>
          <li>
            <span class="highlight-text"
              >Philosophical and ethical discussions</span
            >: philosophical and methodological foundations; legal and societal
            aspects of MIR; ethical issues related to designing and implementing
            MIR tools and technologies.
          </li>
          <li>
            <span class="highlight-text">Human-centered MIR</span>: user
            behavior analysis and mining, user modeling; human-computer
            interaction; music interfaces and services; personalization;
            user-centered evaluation.
          </li>
          <li>
            <span class="highlight-text">Computational musicology</span>:
            mathematical music theory; systematic musicology; digital
            musicology.
          </li>
          <li>
            <span class="highlight-text">Creativity</span>: tools for artists,
            creative practice involving MIR or generative technology, human-ai
            co-creativity, creativity and cognition; creativity and learning;
            computational creativity; humanistic discussions.
          </li>
          <li>
            <span class="highlight-text">Applications</span>: digital libraries
            and archives; music retrieval systems; music recommendation and
            playlist generation; music and health, well-being and therapy; music
            training and education; music composition, performance, and
            production; music videos, multimodal music systems; gaming,
            augmented/virtual reality; music heritage and sustainability;
            business and marketing.
          </li>
        </ul>
        <br />
        <v-divider></v-divider>
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Important Dates</v-card-title
        >
        <v-card-text class="text-body">
          Time zone : Anywhere On Earth (AOE)
        </v-card-text>

        <v-container class="table-container">
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Abstract Submission Due</v-col>
            <v-col cols="5" sm="8">Mar 21, 2025</v-col>
          </v-row>
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Full Paper Submission Due</v-col>
            <v-col cols="5" sm="8">Mar 28, 2025</v-col>
          </v-row>
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Acceptance Notification</v-col>
            <v-col cols="5" sm="8">Jun 6, 2025</v-col>
          </v-row>
          <v-row class="table-row styled-row">
            <v-col cols="7" sm="4">Camera-Ready Upload Due</v-col>
            <v-col cols="5" sm="8">Jun 28, 2025</v-col>
          </v-row>
        </v-container>

        <br />
      </v-card>
      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Selection Process</v-card-title
        >
        <v-card-text class="text-body">
          <span class="highlight-text">Full Paper Review</span>: Each paper will
          be reviewed by at least three reviewers and a program committee member
          (meta-reviewer) who will oversee the process and write a meta-review
          with a recommendation. The Scientific Program Chairs will make the
          final decision based on that recommendation. <br /><br />
          <span class="highlight-text">Double blind review</span>: ISMIR follows
          a double-blind review process - violation may result in desk
          rejection. Authors should not know the names of the reviewers of their
          papers, and reviewers should not know the name(s) of the author(s).
          <br /><br />
          <span class="highlight-text">Evaluation Criteria</span>: Evaluation
          criteria include scholarly/scientific quality, novelty of the paper,
          reusable insights, novelty, readability and paper organization,
          potential to generate discourse, and relevance of the topic to ISMIR.
          Papers which propose brave new ideas are valued. It is helpful to read
          the
          <a
            href="https://ismir.net/reviewer-guidelines/"
            target="_blank"
            style="color: #1487c8"
          >
            reviewer guidelines
          </a>
          before paper submission.
          <br />
        </v-card-text>
        <br />
        <v-divider></v-divider>
        <br /><br />
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >New-to-ISMIR Paper Mentoring Program
        </v-card-title>
        <v-card-text class="text-body">
          New-to-ISMIR Paper Mentoring Program is designed for members new to
          ISMIR to share their advanced-stage work-in-progress ISMIR paper
          drafts with senior members of the ISMIR community as mentors to obtain
          focused review and constructive feedback. The program supplements the
          generic submission guidelines and will be run closely aligned with the
          paper submission deadlines. Please refer to the
          <a
            href="https://ismir2025.ismir.net/new-to-ismir-mentoring-program-2025"
            target="_blank"
            style="color: #1487c8"
          >
            New-to-ISMIR Paper Mentoring Program website
          </a>
          for the details.
        </v-card-text>
        <br />
        <v-divider></v-divider>
        <br /><br />

        <v-card-title class="text-h4 mb-3 font-weight-bold"
          >Hybrid Conference Format
        </v-card-title>
        <v-card-text class="text-body">
          ISMIR 2025 will be organized in a hybrid format with the aim of
          enabling both in-person and online participants to engage with the
          conference program and share their work. In-person participation is
          highly encouraged, and the organizing team is committed to helping
          presenters who wish to attend in person do so (regarding e.g., visa
          issues, financial support). Remote presenters will be required to
          attend (online) the specified session of the in-person program to
          which their paper is assigned.
          <br />
        </v-card-text>
        <br />
        <v-divider></v-divider>
      </v-card>

      <v-card outlined elevation="0" class="pa-4 my-4">
        <v-card-text class="text-body">
          For additional information, you can reach the scientific program
          chairs via
          <span style="color: #1487c8; text-decoration: underline"
            >ismir2025-papers@ismir.net</span
          >
        </v-card-text>
        <v-card-title class="text-h6 mb-3">
          Li Su, Xiao Hu, Magdalena Fuentes, Tomoyasu Nakano
          <br />
          Scientific Program Chairs of ISMIR 2025
        </v-card-title>
      </v-card>
      <br /><br /><br />
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      expandedPanels: [], // ���� Ȯ��� �г��� �����ϴ� �迭
      sections: [
        {
          items: [
            { content: "Abstract Submission Due", date: "March 21, 2025" },
            { content: "Full Paper Submission Due", date: "March 28, 2025" },
            { content: "Acceptance Notification", date: "June 6, 2025" },
            { content: "Camera-Ready Upload Due", date: "June 28, 2025" },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.dot-list {
  list-style-type: disc; /* �۸Ӹ� ��ȣ ��Ÿ�� ���� */
  font-size: 18px;
  padding-left: 40px; /* �鿩���� �� ���� */
  line-height: 1.8;
}
.text-body {
  font-size: 18px; /* �ؽ�Ʈ ��Ʈ ũ�� ���� */
  line-height: 1.6; /* �������� ���� �� ���� ���� */
  white-space: normal; /* �ٹٲ��� ��� */
  overflow-wrap: break-word; /* �ܾ ���� ���� �ʵ��� �ܾ� ������ �ٹٲ� */
}
.v-card-title {
  white-space: normal; /* ���񿡼� �ٹٲ��� ��� */
  overflow-wrap: break-word; /* �ܾ �߸��� �ʵ��� �ٹٲ� */
}
.styled-row {
  border-bottom: 1px solid #d3d3d3; /* ȸ�� �� */
  padding-bottom: 8px; /* �ٰ� ���� �� ���� */
}
/* Notion ��Ÿ���� �ڵ� ���� */
.code-block {
  background-color: #f5f5f5; /* ���� ȸ�� ��� */
  border: 1px solid #e0e0e0; /* ���� �׵θ� */
  border-radius: 4px; /* �𼭸��� �ձ۰� */
  padding: 12px; /* ���� ���� */
  font-family: "Courier New", Courier, monospace; /* ��뽺���̽� ��Ʈ */
  font-size: 14px; /* ������ ũ���� �ؽ�Ʈ */
  overflow-x: auto; /* �� �ڵ忡 ��ũ�� �߰� */
  white-space: pre-wrap; /* �ڵ� �� �ٲ� */
}
.highlight-text {
  color: #004191;
  font-weight: bold;
}
</style>
